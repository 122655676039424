import { logger } from 'cadenza/utils/logging';
import { NAME as offlineNotificationClass } from 'cadenza/offline-notification/offline-notification';
import { OfflineModal, COMPONENT_NAME as offlineModalClass } from 'cadenza/offline-modal/offline-modal';
import { isEdge } from 'cadenza/utils/browser';

const NOTIFICATION_DISPLAYED_TIME = 5000;

const MSG_TYPE = {
  INIT: 'init',
  CADENZA_ONLINE: 'CADENZA_ONLINE',
  CADENZA_OFFLINE: 'CADENZA_OFFLINE',
};

const { offlinePageUrl, swUrl, pollingUrl } = document.getElementById('d-pwa-config').value;

let notificationTimeoutHandle;

// Disabling cookies via browser setting also disables service workers.
if ('serviceWorker' in navigator && navigator.cookieEnabled) {
  if (!isEdge && location.protocol === 'https:') {
    navigator.serviceWorker
      .register(`${swUrl}&offlinePageUrl=${offlinePageUrl}&pollingUrl=${pollingUrl}`)
      .then((registration) => {
        if (registration.active) {
          const serviceWorker = registration.active;
          navigator.serviceWorker.addEventListener('message', (event) => {
            if (event.source === serviceWorker) {
              onMessage(event);
            }
          });
          serviceWorker.postMessage({ type: MSG_TYPE.INIT, offlinePageUrl });
        }
      })
      .catch((error) => {
        logger.error('Could not register the pwa', error);
      });
  } else {
    // Unregister all serviceWorkers installed in a not compatible environment (not compatible for our use cases)
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      })
      .catch((error) => logger.error('Error while unregistering service worker', error));
  }
} else {
  logger.debug(
    'Service worker not enabled due to missing service worker browser support or page not being served over https',
  );
}

function onMessage(event) {
  switch (event.data.type) {
    case MSG_TYPE.CADENZA_OFFLINE:
      onOffline();
      break;
    case MSG_TYPE.CADENZA_ONLINE:
      onOnline();
      break;
    default:
      logger.error('No handler for event of type', event.data.type);
  }
}

function onOffline() {
  const offlineNotification = document.querySelector(`.${offlineNotificationClass}`);
  offlineNotification.show();
  notificationTimeoutHandle = setTimeout(() => {
    offlineNotification.hide();
    showModal();
  }, NOTIFICATION_DISPLAYED_TIME);
}

function onOnline() {
  clearTimeout(notificationTimeoutHandle);
  hideOfflineModal();
  hideOfflineNotification();
}

function hideOfflineModal() {
  const offlineModal = document.querySelector(`.${offlineModalClass}`);
  if (offlineModal) {
    offlineModal.close();
    offlineModal.remove();
  }
}

function hideOfflineNotification() {
  const offlineNotification = document.querySelector(`.${offlineNotificationClass}`);
  if (offlineNotification) {
    offlineNotification.hide();
  }
}

function showModal() {
  if (!document.querySelector(`.${offlineModalClass}`)) {
    hideOfflineNotification();
    new OfflineModal().connectAndShow();
  }
}
