import './offline-notification.css';

import h from 'hyperscript';
import warningIcon from '@disy/cadenza-icons/warning.svg';

import { ProgressSpinner } from 'ui/progress-spinner/progress-spinner';

import { icon } from 'cadenza/utils/icon/icon';

import i18n from './offline-notification.properties';

export const NAME = 'd-offline-notification';
const ONLINE_CLASS = 'online';

class OfflineNotification extends HTMLElement {
  connectedCallback() {
    this.classList.add(NAME, ONLINE_CLASS, 'd-alert', 'd-alert-small', 'd-alert-warning', 'd-stack-h');
    const title = h('.title.d-stack-h.space-2', [icon(warningIcon), h('span', i18n('title.offline'))]);
    const stateIndicator = new ProgressSpinner({ size: 'xs', label: i18n('connecting') });
    stateIndicator.classList.add('d-stack-h', 'space-2');
    this.append(title, stateIndicator);
  }

  _toggle(force) {
    this.classList.toggle(ONLINE_CLASS, force);
  }

  show() {
    this._toggle(false);
  }

  hide() {
    this._toggle(true);
  }

  isDisplayed() {
    return this.classList.contains(ONLINE_CLASS);
  }
}

customElements.define(NAME, OfflineNotification);
